/**
 * # Fonts
 */

/**
 * ## Font Sans Serif
 */
$font-sans_serif-1: 'PT Sans', sans-serif;

%font-sans_serif-normal {
  font-weight: 400;
  font-family: $font-sans_serif-1;
}

%font-sans_serif-bold {
  font-weight: 700;
  font-family: $font-sans_serif-1;
}


/**
 * ## Font Sans Serif 2
 */
$font-sans_serif-2: 'Teko', sans-serif;

%font-sans_serif-2-light {
  font-weight: 300;
  font-family: $font-sans_serif-2;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

%font-sans_serif-2-normal {
  font-weight: 400;
  font-family: $font-sans_serif-2;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

%font-sans_serif-2-medium {
  font-weight: 500;
  font-family: $font-sans_serif-2;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

%font-sans_serif-2-semi_bold {
  font-weight: 600;
  font-family: $font-sans_serif-2;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

%font-sans_serif-2-bold {
  font-weight: 700;
  font-family: $font-sans_serif-2;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
