/**
 * # Helpers
 */

/**
 * ## Clearfix
 */
%clearfix {
  *zoom: 1;
  &:before, &:after {
  display: table;
  content: "";
  }
  &:after {
  clear: both;
  }
}

/**
 * ## Animations
 */
%default-hover-animation {
  @include transition-property(all);
  @include transition-duration(0.15s);
  @include transition-timing-function(easeInOutExpo);
}

%performanceBoostAnimations {
  -webkit-transform: translateZ(0px);
  -moz-transform: translateZ(0px);
  transform: translateZ(0px);
}