
@mixin point($color) {
  position: relative;

  &:before {
    content: "";
    display: block;
    border-radius: 50%;
    background-color: $color;
    width: $point-size;
    height: $point-size;
    position: absolute;
    top: 50%;
    left: 0;
    margin: -8px 0 0 -8px;
    z-index: 1;
  }
}
