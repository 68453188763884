/**
 * ## Footer
 */
.l-footer {
  position: relative;
  border-top: 1px solid #444;
  display: none;
  .l-main-wrap {
    padding: 30px 0;
  }
  &, a {
    color: #000;
  }
}

/**
 * ## Footer Item
 */
.footer-item {
  display: none;
  min-height: 500px;
}

/**
 * ## Nav Footer
 */
.nav-footer {
  position: absolute;
  right: 2%;
  bottom: 20px;
  @extend %font-sans_serif-2-normal;
  font-size: 1.3em;
  
  ul {
    @extend %clearfix;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    float: left;
    margin-left: 5px;
    border-left: 1px solid $footer-nav-border-color;
    padding-left: 5px;
    
    &:first-child {
      margin-left: 0;
      border-left: none;
      padding-left: 0;
    }
  }

  a {
    text-decoration: none;
    color: $footer-nav-text-color;
    line-height: 1.2;
    
    &:hover {
      color: darken($footer-nav-text-color, 15%);
    }
    
    &.current {
      color: $text-color-primary;
    }
  }
}

/**
 * ## Close Footer
 */
.close-footer {
  @extend %default-hover-animation;
  position: absolute;
  left: 2%;
  top: -50px;
  cursor: pointer;
  font-size: 2em;
  color: $footer-nav-text-color;
  
  &:hover {
    color: darken($footer-nav-text-color, 15%);
  }
  
  &:before {
    margin: 0;
  }
}