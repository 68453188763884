/**
 * # Text
 */

/**
 * ## Headlines
 */
h1 {
  @extend %font-sans_serif-bold;
  font-size: 2.5em;
  line-height: 1.2;
  margin: 0;
  margin-bottom: 1em;
}

h2 {
  @extend %font-sans_serif-bold;
  font-size: 1.75em;
  line-height: 1.2;
  margin: 0;
  margin-bottom: 1em;
}

h3 {
  @extend %font-sans_serif-bold;
  font-size: 1.35em;
  line-height: 1.2;
  margin: 0;
  margin-top: 2em;
  margin-bottom: 0.4em;
}


/**
 * ## Paragraph
 */
p {
  margin: 0 0 1em;
}


// ## Typeface
b, strong {
  @extend %font-sans_serif-bold;
}

i, em {
  font-style: italic;
}

// ## Links
a {
  @extend %default-hover-animation;
  color: $text-color-primary;
  text-decoration: underline;
  outline: none;

  &:hover {
    color: $color-primary;
  }
}
