/**
 * ## Variables
 */

/**
 * ## Colors
 */
 @import "colors";
 
/**
 * ## Fonts
 */
$font-size-primary: 14px;
$line-height-primary: 1.6;

/**
 * ## Layout
 */
$l-max-width: 1000px;