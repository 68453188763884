@include keyframes(bubble-fade-in-lines) {
  0% {
    opacity: 0;
    @include transform(scale(0.2));
  }
  100% {
    opacity: 1;
    @include transform(scale(1));
  }
}


@include keyframes(bubble-fade-in) {
  0% {
    opacity: 0;
    @include transform(scale(0.95));
  }
  50% {
    @include transform(scale(1.05));
  }
  100% {
    opacity: 1;
    @include transform(scale(1));
  }
}

#logo {
  position: relative;
  z-index: 9;
  width: 612px;
  height: auto;

  .cssanimations & {
    opacity: 0;
  }

  @include animation-delay(0.5s);
  @include animation-name(bubble-fade-in);
  @include animation-duration(0.5s);
  @include animation-timing-function(ease-in-out);
  @include animation-iteration-count(1);
  @include animation-fill-mode(forwards);
}

.info-boxes-wrap {
  position: relative;
  margin: 150px auto;
  width: 612px;
}

.info-container {
  display: inline-block;
  position: absolute;
  box-shadow: 0px 0px 4px 0px #d4d4d4;
  font-size: 1rem;
  color: #666;
  z-index: 10;

  h1 {
    @extend %performanceBoostAnimations;
    @include user-select(none);
    margin: 0;
    font-size: 20px;
    font-weight: 300;
    text-transform: uppercase;
    padding: 0 $info-padding-x;
    padding-right: $info-padding-x + 6px;
    font-family: 'Teko';
    transition: 0.3s all ease;
    white-space: nowrap;
    line-height: $info-header-height;
  }

  a {
    text-decoration: none;
    display: block;
    outline: none;
  }

  p {
    line-height: 1.5em;
    display: block;
    transition: 0.4s opacity ease;
    margin: 0;
    margin-top: $info-padding-y/2;

    &:first-child {
      margin-top: 0;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    border: 1px solid #e0e0e0;
    border-top: 0;
    padding: 10px 10px;

    li {
      margin-bottom: 10px;
      position: relative;
      padding-left: 120px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  label {
    color: $info-header-bg-color;
    height: 22px;
    display: inline-block;
    padding: 0 10px;
    position: absolute;
    left: 0;
  }

  .info-body-content {
    padding: $info-padding-y $info-padding-x 2*$info-padding-y;
  }

  .info-header,
  .info-body {
    @extend %performanceBoostAnimations;
    transition: 0.4s width ease, 0.4s height ease;
  }

  .info-header {
    cursor: pointer;
    background-color: $info-header-bg-color;
    height: $info-header-height;
    line-height: $info-header-height;

    &:after {
      margin-left: 10px;
      position: absolute;
      top: 0;
      right: $info-padding-x/2;
      font-family: "chevrons";
      content: '\e800';
      line-height: $info-header-height;
      font-size: 12px;
    }
  }

  .info-body {
    background-color: $info-body-bg-color;
    overflow: hidden;
    position: relative;
    box-shadow: inset 0px 0px 20px 5px rgba(255,255,255,1);
    font-size: 14px;

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      border-top: 1px solid #d6d6d6;
      width: 100%;
      box-shadow: 0px 0px 4px 0px #d6d6d6;
    }
  }

  .info-caption {
    text-align: center;
    font-weight: normal;
    line-height: 28px;
    color: $info-header-bg-color;
    font-family: 'PT Sans';
    font-size: 16px;
    line-height: 1em;
    text-transform: uppercase;
    padding: 4px 0 3px;
  }

  .info-next {
    // position: absolute;
    margin-right: -$info-padding-x/2;
    margin-top: -4px;
    // bottom: $info-padding-x/2;
    font-family: 'Teko';
    font-width: 300;
    font-size: 26px;
    float: right;



    .last,
    .next {
      &:before,
      &:after {
        display: inline-block;
        transform: rotate(90deg);
        cursor: pointer;
        font-family: "chevrons";
        font-style: normal;
        font-weight: 200;
        width: 0.9em;
        font-size: 0.9em;
        vertical-align: bottom;
        margin: 0 -4px;
      }
    }

    .last:before {
      content: '\e800';
    }

    .next:after {
      content: '\e801';
    }


  }

  .info-teaser {
    font-weight: bold;
  }

  .info-drop-down {
    overflow: hidden;
    height: 0;
    width: 0;
  }

  &.closed,
  &.active {
    .info-body ~ .info-body {
      display: none;
    }
  }

  &.closed {
    p {
      opacity: 0;
    }

    .info-body {
      height: 0 !important;
    }
  }

  &.active {
    .info-header {

      h1 {
        color: inherit !important;
        font-size: 26px;
      }

      &:after {
        content: '\e801';
      }
    }
  }
}


@mixin info-box($color, $min-width, $max-width) {
  .cssanimations & {
    opacity: 0;
  }
  @include animation-name(bubble-fade-in);
  @include animation-duration(0.5s);
  @include animation-timing-function(ease-in-out);
  @include animation-iteration-count(1);
  @include animation-fill-mode(forwards);

  label {
    background-color: $color;
  }

  .info-body-content {
    width: $max-width - 2 * $info-padding-x;
  }

  .info-header {
    @include point($color);

    &:after {
      color: $color;
    }
  }

  h1 {
    color: $color;
  }

  a {
    color: $color !important;
  }

  .info-body {
    width: $max-width;
  }

  .info-caption {
    background-color: $color;
  }

  // transition states
  .info-header,
  .info-body {
    // width: $min-width;
  }

  &.active {
    .info-header,
    .info-body {
      width: $max-width !important;
    }
  }
}

.connects {
  position: absolute;
  z-index: 1;

  .cssanimations & {
    opacity: 0;
  }

  @include animation-name(bubble-fade-in-lines);
  @include animation-duration(0.75s);
  @include animation-timing-function($ease-in-out-expo);
  @include animation-iteration-count(1);
  @include animation-fill-mode(forwards);
  @include transform-style(preserve-3d);
}


// Was ist die Idee?
#box-01 {
  @include info-box($color-dark-red, $info-min-width, $info-max-width);
  @include animation-delay(1.8s);

  // Position:
  top: 0px;
  left: 400px;
}
#connect-01 {
  @include animation-delay(1.4s);
  top: 27px;
  left: 140px;
  display: block;
  width: 267px;
  height: 146px;
}

// Wozu brauche ich brainsome?
#box-02 {
  @include info-box($color-peru, $info-min-width, $info-max-width);
  @include animation-delay(1.9s);

  // Position:
  top: -67px;
  left: 86px;
}
#connect-02 {
  @include animation-delay(1.5s);
  top: -35px;
  left: 42px;
  display: block;
  width: 192px;
  height: 201px;
}

// Was kann brainsome?
#box-03 {
  @include info-box($color-steel-blue, $info-min-width, $info-max-max-width);
  @include animation-delay(2s);

  // Position:
  top: 155px;
  left: 480px;
}
#connect-03 {
  @include animation-delay(1.6s);
  top: 13px;
  left: 227px;
  display: block;
  width: 251px;
  height: 251px;
}

// Wer steckt dahinter?
#box-04 {
  @include info-box($color-black, $info-min-width, $info-max-max-width);
  @include animation-delay(2s);

  // Position:
  top: 225px;
  left: -140px;
}
#connect-04 {
  @include animation-delay(1.6s);
  top: 167px;
  left: -136px;
  display: block;
  width: 285px;
  height: 198px;
}

// Brainsome in der Praxis: torial
#box-05 {
  @include info-box($color-yello-green, $info-min-width, $info-max-width);
  @include animation-delay(2.2s);

  // Position:
  top: 460px;
  left: 172px;
}
#connect-05 {
  @include animation-delay(1.7s);
  top: 19px;
  left: 47px;
  display: block;
  width: 193px;
  height: 478px;
}

#box-05 .for-piqd {
  $color: #0D8FAF !important;

  a {
    color: $color;
  }

  &.info-caption {
    background-color: $color;
  }
}