/**
 * # Main Stylesheet
 */

/**
 * ## External Libraries
 */
@import "lib/normalize";
@import "~node-bourbon/assets/stylesheets/_bourbon.scss";

/**
 * ## Config
 */
@import "config/vars";
@import "config/helpers";
@import "config/fonts";
@import "chevrons";

/**
 * ## Base Styles
 */
body {
  @extend %font-sans_serif-normal;
  font-size: $font-size-primary;
  line-height: $line-height-primary;
  color: $text-color-primary;
  overflow-y: scroll;
}

@import "base/text";

// our stuff
@import "chevrons";

/**
 * ## Layouts
 */
@import "templates/layout";

/**
 * ## Components
 */
@import "components/info-boxes/index";
@import "components/footer/index";

/**
 * ## others
 */
 @import "backgrounds";
 @import "svg-animation";
