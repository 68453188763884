#background-gradient {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-image: url(../images/background.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-size: auto 100%;
  background-position: center;
}