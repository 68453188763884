
@font-face {
  font-family: 'chevrons';
  src: url('../fonts/icons/chevrons.eot?58293055');
  src: url('../fonts/icons/chevrons.eot?58293055#iefix') format('embedded-opentype'),
       url('../fonts/icons/chevrons.woff?58293055') format('woff'),
       url('../fonts/icons/chevrons.ttf?58293055') format('truetype'),
       url('../fonts/icons/chevrons.svg?58293055#chevrons') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'chevrons';
    src: url('../fonts/icons/chevrons.svg?58293055#chevrons') format('svg');
  }
}
*/

 [class^="tile-"]:before, [class*=" tile-"]:before {
  font-family: "chevrons";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.tile-down-open:before { content: '\e800'; } /* '' */
.tile-up-open:before { content: '\e801'; } /* '' */