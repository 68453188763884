/**
 * # SVG CONTAINER
 */
.svg-container {
  position: relative;
  width: 612px;
  margin: 150px auto;
  
  svg {
    position: relative;
    z-index: 9;
    display: block;
    overflow: hidden;
    max-width: 100%;
    @extend %performanceBoostAnimations;
    
    line, circle, path {
      @extend %performanceBoostAnimations;
    }
    
    line, circle, path {
      @include transition-property(fill,stroke);
      @include transition-duration(1.3s);
      @include transition-timing-function(easeInOutExpo);
      @include transform-style(preserve-3d);
    }
  }
}


/**
 * ## SVG ELEMENTS
 */
.circle-black {
  fill: #000;
}

.circle-green {
  fill: #A8C630;
}

.circle-red {
  fill: #C1182A;
  
  &:hover {
    fill: #000;
  }
}

.st0{fill:none;stroke:#000000;stroke-width:3;stroke-miterlimit:10;}
.st1{fill:none;stroke:#7BACDC;stroke-width:14;stroke-miterlimit:10;}
.st2{fill:#FFFFFF;stroke:#000000;stroke-width:3;stroke-miterlimit:10;}
.st3{fill:#7BACDC;}
.st4{fill:none;stroke:#A9C731;stroke-width:14;stroke-miterlimit:10;}
.st5{fill:none;stroke:#C2192B;stroke-width:14;stroke-miterlimit:10;}
.st6{fill:#C2192B;}
.st7{fill:#A9C731;}
.st8{fill:none;stroke:#DAA02F;stroke-width:14;stroke-miterlimit:10;}

.startgrey {
  @extend %performanceBoostAnimations;

  line {
    stroke: #ccc;
  }
  circle {
    fill: #ccc;
  }
  path {
    fill: #ccc;
  }

  .st0,
  .st1,
  .st4,
  .st5,
  .st8 {
    fill: none;
    stroke: #ccc;
  }
}