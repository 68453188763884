/**
 * # Layout
 */

.l-main-wrap {
  position: relative;
  max-width: $l-max-width;
  margin: 0 auto;
}

.l-content {
  @extend %clearfix;
  position: relative;
}

// Main content
.main-content {
  min-height: 100vh;
}

/**
 * ## Template 2-1
 */
.l-2-1 {
  .content, .sidebar {
    float: left;
  }

  .content {
    width: 65%;
    margin-right: 5%;
  }

  .sidebar {
    width: 30%
  }
}