
// colors
$color-dark-red: rgb(194, 25, 43);
$color-peru: rgb(218, 160, 47);
$color-yello-green: rgb(169, 199, 49);
$color-steel-blue: rgb(123, 172, 220);
$color-black: rgb(0, 0, 0);

// general
$point-size: 16px;
$info-header-height: 61px;
$info-padding-x: 28px;
$info-padding-y: 20px;
$info-header-bg-color: rgba(255, 255, 255, 1);
$info-body-bg-color: rgba(255, 255, 255, 0.95);

// sizes
$info-min-width: 300px;
$info-max-width: 370px;
$info-max-max-width: 524px;
